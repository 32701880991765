/* i used black & white neumorphism css style, you can also Genaretor this css from many website, do google fiend many site fot this */

/* kibria inside_effect btn style  */
.kib_inside_effect {
  background: #ebecf0;
  outline: none;
  border: none;
  border: none;
  outline: none;
  box-shadow: -4px -4px 12px rgb(255, 255, 255),
    4px 4px 12px rgba(121, 130, 160, 0.747);
  font-family: "Poppins", sans-serif;
  transition: color 280ms ease-in-out;
}
.kib_inside_effect:hover {
  box-shadow: inset -2px -2px 6px rgb(255, 255, 255),
    inset 2px 2px 6px rgba(121, 130, 160, 0.747);
  color: #0ab299;
}

.kib_box_shadow {
  /* background: linear-gradient(145deg, #dbdbdb, #fdffff); */
  background: #ebecf0;
  box-shadow: -4px -4px 12px rgb(255, 255, 255),
    4px 4px 12px rgba(121, 130, 160, 0.747);
}
.kib_box_shadow_hover:hover {
  box-shadow: inset -2px -2px 6px rgb(255, 255, 255),
    inset 2px 2px 6px rgba(121, 130, 160, 0.747);
  color: #0ab299;
}

.gradient_text_box {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#30cfd0),
    to(#330867)
  );
  background: linear-gradient(to right, #185ef0, #30cfd0);
  /* background: linear-gradient(to right,#fd4475, #30cfd0 ); */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Logo Rotate Css start */
/* <img src={logo} className="App-logo" alt="logo" /> */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Logo Rotate Css End */

.tooltip2 {
  position: relative;
  display: inline-block;
}

.tooltip2 .tooltiptext2 {
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 50%;
  margin-left: -60px;
}
.tooltip2:hover .tooltiptext2 {
  visibility: visible;
}

.kib_w_bg{
  background-color: #ffffff;
}
.kib_light_p_bg{
  background-color: #efeeff;
}
.service_cart_figure{
align-items: flex-end !important;
}


.listStyleCheckMarkImg {
  list-style-image: url('./Assets/img/Check_Mark_img.svg');
}